<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="マニフェスト代理予約" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- マニフェスト情報 -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">マニフェスト情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>予約件数</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <Selectbox
                    class="sm"
                    name="reserve-num"
                    :value.sync="formValue.countInfo"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.countInfo.isValid"
                    :errMsg.sync="validate.countInfo.errMsg"
                  >
                    <option
                      v-for="n in getReserveNumber"
                      :key="'rsvn' + n"
                      :value="n"
                    >
                      {{ n }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>排出業者</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <Selectbox
                    :value.sync="proxyGenerateId"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.proxyGenerateId.isValid"
                    :errMsg.sync="validate.proxyGenerateId.errMsg"
                    :change="changeProxyGenerateHandler"
                  >
                    <option
                      v-for="proxyGenerate in proxyGenerateCompanyList"
                      :key="'prxy' + proxyGenerate.id"
                      :value="proxyGenerate.id"
                    >
                      {{ proxyGenerate.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業場</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <v-autocomplete ref="generate_store_autocomplete"
                                  :isValid="validate.generateStoreId.isValid"
                                  :errMsg="validate.generateStoreId.errMsg"
                                  @change="s => {onChange(s, 'generate_store_autocomplete')}" placeholder=""
                                  class="c-input md_l" v-bind:initItems="this.generateStoreList"
                                  :items="this.selectGenerateStoreList" :min-len="0" :wait="300"
                                  @update-items='updateGenerateStoreList' v-model="selectedGenerateStore"
                                  :component-item='selectTemplate' :auto-select-one-item="this.autoSelect"
                                  :get-label='getLabel'>
                  </v-autocomplete>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>引き渡し日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputDate
                    :value.sync="formValue.scheduleSettingInfo.firstCollectionDate"
                    :placeholder="'0000/00/00'"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.collectionDate.isValid"
                    :errMsg.sync="validate.collectionDate.errMsg"
                  />
                  <InputTime
                    class="sm"
                    :value.sync="formValue.scheduleSettingInfo.collectionTime"
                    :placeholder="'00:00'"
                    :validation="validateJustBlank"
                  />
                </div>
                <div class="c-inputWrap__items__cols l-area">
                  <Selectbox v-if="formValue.scheduleSettingInfo.firstCollectionDate"
                    name="recurrying"
                    :value.sync="selectedRecurrying"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.selectedRecurrying.isValid"
                    :errMsg.sync="validate.selectedRecurrying.errMsg"
                    :change="onChangeRecurryingHandler"
                  >
                    <option value="">繰り返さない</option>
                    <option value="every-day">毎日</option>
                    <option value="every-week">毎週{{ getFirstCollectionDateWeekOfDayLabel() }}曜日</option>
                    <option value="custom">カスタム</option>
                  </Selectbox>
                </div>
                <div class="c-inputWrap__items__cols center sm-mgn" v-if="selectedRecurrying === 'custom'">
                  <div>
                    {{ formValue.recurringInfo.intervalValue }}
                    {{ formValue.recurringInfo.recurringTypeId === recurryingType.DAY ? '日ごと' : formValue.recurringInfo.recurringTypeId === recurryingType.WEEK ? '週間ごと' : '' }}
                    {{ weekOfDayTypeIdList.map(id => weekOfDay[id - 1].label + '曜日').join('、') }}
                  </div>
                  <div class="c-btn secondary sm" @click="isRecurryingModalShow = true">
                    カスタム設定
                  </div>
                </div>
              </div>
            </div>
            <!-- 繰り返すのを設定する場合のみ、繰り返しの終了日を表示する -->
            <div v-if="selectedRecurrying !== '' && formValue.scheduleSettingInfo.firstCollectionDate !== ''" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>繰り返し終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputDate
                    :validation="validateJustBlank"
                    :value.sync="formValue.recurringInfo.recurringEndDate"
                    :placeholder="'0000/00/00'"
                    :isValid.sync="validate.recurringEndDate.isValid"
                    :errMsg.sync="validate.recurringEndDate.errMsg"
                  />
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>登録担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols">
                      <div class="c-radio">
                        <input
                          id="register-select"
                          type="radio"
                          :value="true"
                          v-model="isInputRegisterMemberForm"
                          v-on:change="changeRegistMemberInputForm"
                        />
                        <label class="c-radio__label" for="register-select">
                          <span class="c-radio__box"></span>リストから選択
                        </label>
                      </div>
                      <div class="c-radio">
                        <input
                          id="register-input"
                          type="radio"
                          :value="false"
                          v-model="isInputRegisterMemberForm"
                          v-on:change="changeRegistMemberInputForm"
                        />
                        <label class="c-radio__label" for="register-input">
                          <span class="c-radio__box"></span>直接入力
                        </label>
                      </div>
                    </div>
                    <Selectbox
                      v-if="isInputRegisterMemberForm"
                      class="c-inputWrap__items__cols"
                      name="register-in-charge"
                      :value.sync="formValue.manifestInfo.registMember.id"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="opt in memberOpt"
                        :key="'mp' + opt.id"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                    <InputText
                      v-if="!isInputRegisterMemberForm"
                      class="c-inputWrap__items__cols"
                      style="width: 22rem"
                      placeholder="入力してください"
                      tooltip="12文字以内で入力してください"
                      :validation="validateMaxLen"
                      :validateArg="12"
                      inputType="text"
                      input_id="input_registerMember"
                      :value.sync="formValue.manifestInfo.registMember.name"
                      :isValid.sync="validate.registMemberName.isValid"
                      :errMsg.sync="validate.registMemberName.errMsg"
                    />
                  </div>
                </div>
                <!-- /.c-inputWra -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>引き渡し担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols">
                      <div class="c-radio">
                        <input
                          id="generate-select"
                          type="radio"
                          :value="true"
                          v-model="isInputGenerateMemberForm"
                          v-on:change="changeGenerateMemberInputForm"
                        />
                        <label class="c-radio__label" for="generate-select">
                          <span class="c-radio__box"></span>リストから選択
                        </label>
                      </div>
                      <div class="c-radio">
                        <input
                          id="generate-input"
                          type="radio"
                          :value="false"
                          v-model="isInputGenerateMemberForm"
                          v-on:change="changeGenerateMemberInputForm"
                        />
                        <label class="c-radio__label" for="generate-input">
                          <span class="c-radio__box"></span>直接入力
                        </label>
                      </div>
                    </div>
                    <Selectbox
                      v-if="isInputGenerateMemberForm"
                      class="c-inputWrap__items__cols"
                      name="emit-in-charge"
                      :value.sync="formValue.manifestInfo.generateMember.id"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="opt in memberOpt"
                        :key="'gmp' + opt.id"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                    <InputText
                      v-if="!isInputGenerateMemberForm"
                      class="c-inputWrap__items__cols"
                      style="width: 22rem"
                      placeholder="入力してください"
                      tooltip="12文字以内で入力してください"
                      :validation="validateMaxLen"
                      :validateArg="12"
                      inputType="text"
                      input_id="input_generateMember"
                      :value.sync="formValue.manifestInfo.generateMember.name"
                      :isValid.sync="validate.generateMemberName.isValid"
                      :errMsg.sync="validate.generateMemberName.errMsg"
                    />
                  </div>
                </div>
                <!-- /.c-inputWra -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>代理登録担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="emit-in-charge"
                        :value.sync="
                          formValue.manifestInfo.proxyCollectMemberId
                        "
                        :validation="validateJustBlank"
                      >
                        <option
                          v-for="opt in collectMemberOpt"
                          :key="'pgc' + opt.id"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWra -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 廃棄物情報 -->
        <div class="l-contents l-area">
          <div class="l-box">
            <h3 class="c-lead icon">廃棄物情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>ルート</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !formValue.generateStoreId }">
                <div class="non_side_layout">
                  <div
                    class="c-btn secondary small l-item"
                    @click="showRouteSelectModal()"
                  >
                    ルート選択
                  </div>
                </div>
                <p class="c-input__errMsg">{{ validate.route.errMsg }}</p>
                <div v-if="formValue.manifestInfo.routeId">
                  <dl class="c-dl">
                    <dt>廃棄物種類：</dt>
                    <dd>{{ jwnetItemTypeName }}</dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>ルート名称：</dt>
                    <dd>{{ routeDetailInfo.routeName }}</dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>排出事業場：</dt>
                    <dd>{{ routeCompanyName.generateStore }}</dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬業者：</dt>
                    <dd>{{ routeCompanyName.collectCompany }}</dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>処分場：</dt>
                    <dd>{{ routeCompanyName.disposalSite }}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <template v-if="formValue.manifestInfo.routeId">
              <!-- 事業場変更可能フラグがONのルートを選択時   -->
              <template v-if="routeDetailInfo.isChangeStore">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>排出事業場名</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                        :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreName"
                        inputTyle="text"
                        tooltip="60文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="60"
                        :isValid.sync="validate.anyGenerateStoreName.isValid"
                        :errMsg.sync="validate.anyGenerateStoreName.errMsg"
                    />
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>排出事業場住所</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>郵便番号</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div class="c-inputWrap__items__cols center sm-mgn">
                          <InputText
                            :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode"
                            inputTyle="number"
                            placeholder="1234567(ハイフンなし)"
                            tooltip="7桁の半角数字で入力してください。"
                            :validation="validateNumberEqualLen"
                            :validateArg="7"
                            :isValid.sync="validate.zipCode.isValid"
                            :errMsg.sync="validate.zipCode.errMsg"
                          />
                          <div
                            class="c-btn secondary sm"
                            @click="searchAddressHandler()"
                          >
                            住所自動入力
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>都道府県</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <Selectbox
                            class=""
                            name="prefecture"
                            :value.sync="selectedPrefectureTemp"
                            :validation="validateJustBlank"
                            :isValid.sync="validate.prefecture.isValid"
                            :errMsg.sync="validate.prefecture.errMsg"
                          >
                            <option
                              v-for="prefecture in prefectures"
                              :key="prefecture.id"
                              :value="prefecture.name"
                            >
                              {{ prefecture.name }}
                            </option>
                          </Selectbox>
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>市区町村</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <InputText
                            :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address"
                            inputTyle="text"
                            placeholder="市区町村"
                            tooltip="100文字以内で入力してください"
                            :validation="validateMaxLen"
                            :validateArg="100"
                            :isValid.sync="validate.address.isValid"
                            :errMsg.sync="validate.address.errMsg"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>番地建物名号室</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <InputText
                              :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName"
                              inputTyle="text"
                              placeholder="番地建物名号室"
                              tooltip="40文字以内で入力してください"
                              :validation="validateMaxLen"
                              :validateArg="40"
                              :isValid.sync="validate.buildingName.isValid"
                              :errMsg.sync="validate.buildingName.errMsg"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /.c-inputWrap -->
                  </div>
                </div>
              </template>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物名称</label>
                </div>
                <div class="c-inputWrap__items">
                  <Selectbox
                    name="disposal-kind"
                    :value.sync="formValue.wasteInfo.wasteNameId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in wasteNameOpt"
                      :key="'jwi' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>詳細名称</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="formValue.wasteInfo.detailWasteName"
                    placeholder="詳細名称"
                    tooltip="30文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="30"
                    :isValid.sync="validate.detailWasteName.isValid"
                    :errMsg.sync="validate.detailWasteName.errMsg"
                  />
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物数量</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols l-area">
                    <InputText
                      inputType="number"
                      :value.sync="formValue.wasteInfo.wasteQuantity"
                      tooltip="廃棄物数量は数字で入力してください。"
                      :validation="validateNumberOrFloat"
                      :isValid.sync="validate.wasteQuantity.isValid"
                      :errMsg.sync="validate.wasteQuantity.errMsg"
                    />
                    <Selectbox
                      class="sm"
                      name="unit"
                      :value.sync="formValue.wasteInfo.wasteUnitTypeId"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.wasteUnitTypeId.isValid"
                      :errMsg.sync="validate.wasteUnitTypeId.errMsg"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(opt, index) in wasteUnitOpt"
                        :key="'wu' + index"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>数量確定者</label>
                </div>
                <div class="c-inputWrap__items">
                  <Selectbox
                    name="number-in-charge"
                    :value.sync="formValue.wasteInfo.quantityConfirmBusinessTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in confirmMemberOpt"
                      :key="'cm' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>荷姿</label>
                </div>
                <div class="c-inputWrap__items">
                  <Selectbox
                    name="package-name"
                    :value.sync="formValue.wasteInfo.shapeTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in shapeOpt"
                      :key="'shp' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>荷姿数量</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <InputText
                      :value.sync="formValue.wasteInfo.shapeQuantity"
                      inputType="number"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.shapeQuantity.isValid"
                      :errMsg.sync="validate.shapeQuantity.errMsg"
                    />
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">
                  <Selectbox
                    name="package-name"
                    :value.sync="formValue.wasteInfo.disposalWayTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in disposalWayTypesOpt"
                      :key="'dwt' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>

              <div v-if="isDisplayHarmfulSelection" class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>有害物質情報</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    v-for="(harmfulItem, itemIndex) in formValue.harmfulInfo"
                    :key="`hmfl-item-${itemIndex}`"
                    class="l-flex start"
                  >
                    <Selectbox
                      class="l-area"
                      name="package-name"
                      :value.sync="harmfulItem.harmfulTypeId"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(harmfulType, index) in harmfulTypes"
                        :key="'hmfl-' + index"
                        :value="harmfulType.id"
                      >
                        {{ harmfulType.name }}
                      </option>
                    </Selectbox>
                    <div
                      class="c-input__addBtn c-btn secondary delete sm"
                      @click="removeHarmfulTypeHandler(harmfulItem)"
                    >
                      削除
                    </div>
                  </div>
                  <div class="c-btn secondary add sm" v-if="formValue.harmfulInfo && formValue.harmfulInfo.length < 6" @click="addHarmfulTypeHandler">
                    有害物質名を追加
                  </div>
                </div>
              </div>

              <div class="c-inputWrap" v-for="(contact, contactIndex) in formValue.contactNoInfo.contactNoList" :key="`ctct-item-${contactIndex}`">
                <div class="c-inputWrap__label">
                  <label>連絡番号{{ contactIndex+1 }}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="l-flex start">
                    <InputText
                      :value.sync="contact.contactNo"
                      inputTyle="text"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.contactNo[contactIndex].isValid"
                      :errMsg.sync="validate.contactNo[contactIndex].errMsg"
                    />
                  </div>
                </div>
              </div>

              <div class="c-inputWrap" v-for="(remarks, remarkIndex) in formValue.remarksInfo.remarksList" :key="'remarks' + remarkIndex">
                <div class="c-inputWrap__label">
                  <label>備考{{ remarkIndex+1 }}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div>
                    <InputText
                      tooltip="備考は50バイト以内で入力してください。"
                      :value.sync="remarks.remarks"
                      inputType="text"
                      :validation="validateByteLen"
                      :validateArg="50"
                      :isValid.sync="validate.remarks[remarkIndex].isValid"
                      :errMsg.sync="validate.remarks[remarkIndex].errMsg"
                    />
                  </div>
                </div>
              </div>

              <!-- ルートラベル -->
              <div
                class="c-inputWrap"
                v-for="(routeLabel, routeLabelIndex) in formValue.labelInfo.labels"
                :key="'label-' + routeLabelIndex"
              >
                <div class="c-inputWrap__label">
                  <label>{{ routeLabel.header }}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <InputText
                      class="xlg"
                      inputType="text"
                      :value.sync="routeLabel.labelValue"
                      :validation="validateJustBlank"
                    />
                    <span v-if="routeLabel.labelUnit">
                      {{ routeLabel.labelUnit }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- WDS -->
              <div v-if="routeDetailInfo.wdsFileName" class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>WDS</label>
                </div>
                <div class="c-inputWrap__items">
                  <a class="c-text text-link c-entrustText__text" @click="downloadWDS()">
                    {{ routeDetailInfo.wdsFileName }}
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- /.l-contents -->
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler">登録</div>
        </div>
      </div>
    </main>

    <!-- 繰り返しモーダル -->
    <div class="c-modal md" v-show="isRecurryingModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">繰り返し設定</h5>
          <span
            class="c-modal__head__close"
            @click="closeCustomRecurryModalHandler"
          ></span>
        </div>
        <div class="c-modal__body">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>間隔</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <Selectbox
                  class="sm"
                  name="other-route-num"
                  :value.sync="formValue.recurringInfo.intervalValue"
                  :validation="validateNumber"
                >
                  <option
                    v-for="n in getIntervals"
                    :key="'intv' + n"
                    :value="n"
                  >
                    {{ n }}
                  </option>
                </Selectbox>
                <Selectbox
                  class="mid"
                  name="other-route-period"
                  :value.sync="formValue.recurringInfo.recurringTypeId"
                  :validation="validateJustBlank"
                >
                  <option
                    v-for="(opt, index) in recurryingTypesOpt"
                    :key="'rct' + index"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
          </div>
          <div class="c-inputWrap" v-if="formValue.recurringInfo.recurringTypeId === recurryingType.WEEK">
            <div class="c-inputWrap__label">
              <label>曜日</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols l-area">
                <div class="c-checkbox" v-for="day in weekOfDay" :key="day.id">
                  <input
                    :id="'day-cb-' + day.id"
                    type="checkbox"
                    :value="day.id"
                    v-model="weekOfDayTypeIdList"
                  />
                  <label class="c-checkbox__label" :for="'day-cb-' + day.id">
                    <span class="c-checkbox__box"></span>
                    {{ day.label }}
                  </label>
                </div>
              </div>
              <p v-if="!weekOfDayTypeIdList.length" class="c-input__errMsg">曜日を選択して下さい</p>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="closeCustomRecurryModalHandler"
          >
            閉じる
          </div>
          <div class="c-btn primary small" @click="setCustomRecurryinHandler">
            登録
          </div>
        </div>
      </div>
    </div>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを{{ formValue.countInfo }}件予約します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="() => (isConfirmModalShow = false)"
          >
            戻る
          </div>
          <div class="c-btn primary small" :class="{ disabled: isCallAPIProcess }" @click="submit">登録</div>
        </div>
      </div>
    </div>

    <!--ルート選択モーダル-->
    <div class="c-modal xlg" v-if="isRouteSelectModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">ルート設定</h5>
          <span
            class="c-modal__head__close"
            @click="isRouteSelectModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body">

          <!--検索条件開始-->
          <div class="c-inputWrap">
            <div>
              <span class="text-label">廃棄物種類</span>
              <Selectbox
                class="mid"
                :value.sync="routeModalSearchParam.jwnetItemTypeId"
                :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                  v-for="(opt, index) in jwnetItemsOpt"
                  :key="`jwnet-opt-${index}`"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">ルート名称</span>
                <InputText
                  class="xlg"
                  :value.sync="routeModalSearchParam.routeName"
                  inputType="text"
                  placeholder="ルート名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">廃棄物名称</span>
                <InputText
                  class="xlg"
                  :value.sync="routeModalSearchParam.wasteName"
                  inputType="text"
                  placeholder="廃棄物名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">運搬事業者</span>
                <v-autocomplete ref="collect_company_autocomplete" @change="s => {onChange(s, 'collect_company_autocomplete')}" placeholder="運搬事業者名称を入力" class="c-input md_l" :items="this.selectCollectCompanyList" :min-len="0" :wait="300" @update-items='updateCollectCompanyList' v-model="selectedCollectCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分事業者</span>
                <v-autocomplete ref="disposal_company_autocomplete" @change="s => {onChange(s, 'disposal_company_autocomplete')}" placeholder="処分事業者名称を入力" class="c-input md_l" :items="this.selectDisposalCompanyList" :min-len="0" :wait="300" @update-items='updateDisposalCompanyList' v-model="selectedDisposalCompany" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分場</span>
                <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="処分場名称を入力" class="c-input md_l" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-bind:initItems="disposalSiteList" v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                </v-autocomplete>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <!--廃棄物区分条件-->
              <div>
                <span class="text-label">廃棄物区分</span>
                <div class="l-flex cols">
                  <div class="c-input" v-for="(wasteTypeId, wtIndex) in wasteTypeIdOpt" :key="'wasteTypeId-' + wtIndex">
                    <div class="c-checkbox">
                      <input
                        :id="'wasteTypeId-' + wtIndex"
                        type="checkbox"
                        v-model="routeModalSearchParam.wasteTypeIdList"
                        :value="wasteTypeId.id"
                      />
                      <label class="c-checkbox__label" :for="'wasteTypeId-' + wtIndex">
                        <span class="c-checkbox__box"></span>
                        {{ wasteTypeId.name }}
                      </label>

                      <!--産業廃棄物区分の場合-->
                      <template v-if=" wasteTypeId.id === wasteTypeIdOpt[0].id && routeModalSearchParam.wasteTypeIdList.includes(wasteTypeId.id)">
                        <div class="l-flex wrap full" >
                          <span>(</span>
                          <div class="c-input" v-for="(industrialWasteType, industrialWasteTypeIndex) in industrialWasteJwnetTypeOpt" :key="'industrialWasteType-' + industrialWasteTypeIndex">
                            <input
                              :id="'industrialWasteType-' + industrialWasteTypeIndex"
                              type="checkbox"
                              v-model="routeModalSearchParam.industrialWasteJwnetTypeIdList"
                              :value="industrialWasteType.id"
                            />
                            <label class="c-checkbox__label" :for="'industrialWasteType-' + industrialWasteTypeIndex">
                              <span class="c-checkbox__box"></span>
                              {{ industrialWasteType.name }}
                            </label>
                          </div>
                          <span>)</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!--マニフェスト区分条件-->
              <div>
                <span class="text-label">マニフェスト区分</span>
                <div class="l-flex cols">
                  <div class="c-input" v-for="(manifestType, manifestTypeIndex) in manifestTypeOpt" :key="'manifestType-' + manifestTypeIndex">
                    <div class="c-checkbox">
                      <input
                        :id="'manifestType-' + manifestTypeIndex"
                        type="checkbox"
                        v-model="routeModalSearchParam.manifestTypeIdList"
                        :value="manifestType.id"
                      />
                      <label class="c-checkbox__label" :for="'manifestType-' + manifestTypeIndex">
                        <span class="c-checkbox__box"></span>
                        {{ manifestType.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div>
              <span class="text-label">お気に入り</span>
              <div class="l-flex cols">
                <div class="c-input">
                  <div class="c-checkbox">
                    <input
                      id="is-favorite"
                      type="checkbox"
                      v-model="routeModalSearchParam.isFavorite"
                    />
                    <label class="c-checkbox__label" for="is-favorite">
                      <span class="c-checkbox__box"></span>
                      有
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="l-container">
            <div class="l-btns l-block-lg">
              <div class="c-btn secondary small" @click="resetRouteModalSearchHandler">
                条件をリセット
              </div>
              <div class="c-btn primary small" @click="routeModalSearchHandler">検索</div>
            </div>
          </div>
          <!--検索条件終了-->
          <!--検索結果表示開始-->
          <div class="c-infoList__row head">
            <div class="c-infoList__cell xxsm"></div>
            <div class="c-infoList__cell md">ルート名称</div>
            <div class="c-infoList__cell sm">廃棄物区分</div>
            <div class="c-infoList__cell sm">廃棄物種類</div>
            <div class="c-infoList__cell sm">廃棄物名称</div>
            <div class="c-infoList__cell md-m">運搬事業者</div>
            <div class="c-infoList__cell md">処分場</div>
          </div>
          <div
            v-for="item of this.narrowedRoutesList"
            :key="item.routeId"
            class="c-infoList__row list"
            :class="{'checked': selectedRoute === item.routeId}"
          >
            <div class="c-infoList__cell xxsm">
              <div class="c-radio">
                <input
                  :id="'route' + item.routeId"
                  type="radio"
                  :value="item.routeId"
                  v-model="selectedRoute"
                />
                <label class="c-radio__label" :for="'route' + item.routeId">
                  <span class="c-radio__box"></span>
                </label>
              </div>
            </div>
            <div class="c-infoList__cell md">
              <div class="c-text vertical_dot">{{ item.routeName }}</div>
            </div>
            <div class="c-infoList__cell sm">
              <div class="c-text horizontal_dot">
                {{ item.wasteType.name }}/{{ item.manifestType.id === 1 ? "電子" : "紙" }}
              </div>
            </div>
            <div class="c-infoList__cell sm">
              <div class="c-text horizontal_dot">
                {{ item.jwnetItemType.name }}
              </div>
            </div>
            <div class="c-infoList__cell sm">
              <div class="c-text horizontal_dot">
                {{ item.jwnetItemType.wasteName }}
              </div>
            </div>
            <div class="c-infoList__cell md-m">
              <CommaText :items="item.collectCompanys" arg="name" :maxLength="15" />
            </div>
            <div class="c-infoList__cell md">
              <div class="c-text vertical_dot">{{ item.disposalSite.name + " JWNETID:" + item.jwnetId }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn primary small"
            @click="clickSetRoute(selectedRoute)"
          >
            OK
          </div>
        </div>
      </div>
    </div>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import InputTime from "@/components/parts/input/InputTime";
import AddressSelectModal from "./components/AddressSelectModal.vue";
import CommaText from "@/components/parts/CommaText";
import validation from "@/mixin/validation";
import validate from "./mixins/validate";
import reserveFormInfo from "./mixins/reserveFormInfo";
import callApi from "./mixins/callApi";
import recurrying from "./mixins/recurrying";
import route from "./mixins/route";
import textarea from "./mixins/textarea";
import harmfulType from "./mixins/harmfulType";
import addressSearch from './mixins/addressSearch';
import common from "@/mixin/common";
import contactNo from "./mixins/contactNo";
import { API_URL, WEEK_OF_DAY, DOMAIN, CUSTOM_MAX_INTERVAL,COOKIE_INFO } from "@/const/index";
import moment from "moment";
import { saveAs } from "file-saver";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";

export default {
  name: "manifest-reserve",
  components: {
    InputText,
    Selectbox,
    InputDate,
    InputTime,
    AddressSelectModal,
    CommaText,
  },
  mixins: [
    addressSearch,
    validation,
    validate,
    reserveFormInfo,
    callApi,
    recurrying,
    route,
    textarea,
    harmfulType,
    common,
    contactNo,
  ],
  data() {
    return {
      selectedCollectCompany: '', //選択した運搬事業者
      selectedDisposalCompany: '', //選択した処分事業者
      selectedDisposalSite: '', //選択した処分場
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
      selectedGenerateStore : '', //選択した排出事業場
      selectedRoute: null,
      isRecurryingModalShow: false,
      isConfirmModalShow: false,
      isRouteSelectModalShow: false,
      selectedRecurrying: "",
      recurryingType: DOMAIN.RECURRING_TYPE,
      weekOfDayTypeIdList: [],
      weekOfDay: WEEK_OF_DAY,
      proxyGenerateId: null, // 排出業者の値、submitしないからここで管理
      errMsgs: [],
      selectedJwnetItem: {},
      selectedPrefectureTemp: null,
      wasteTypeIdOpt: [
        { id: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , name: "産業廃棄物" },
        { id: DOMAIN.WASTE_TYPE.VALUABLES , name: "有価物" },
        { id: DOMAIN.WASTE_TYPE.COMMON_WASTE , name: "一般廃棄物" },
      ],
      industrialWasteJwnetTypeOpt: [
        { id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , name: "普通産業廃棄物" },
        { id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , name: "特別管理産業廃棄物" },
      ],
      manifestTypeOpt: [
        { id: DOMAIN.MANIFEST_TYPE.DIGITAL , name: "電子" },
        { id: DOMAIN.MANIFEST_TYPE.ANALOG , name: "紙マニフェスト" },
      ],
      // ルート選択肢モーダル検索パラメータ
      routeModalSearchParam: {
        jwnetItemTypeId: "", // 廃棄物種類ID
        routeName: "", // ルート名称
        wasteName: "", // 廃棄物名称
        collectCompanyId: null, // 運搬事業者Id
        disposalCompanyId: null, // 処分事業者Id
        disposalSiteId: null, // 処分場ID
        wasteTypeIdList: [],//廃棄物区分
        manifestTypeIdList: [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        isFavorite: false, // お気に入りフラグ
      },
      isCallAPIProcess: false,
      isInputRegisterMemberForm: true,
      isInputGenerateMemberForm: true,
    };
  },
  methods: {

    // 「ルート選択」ボタンを押すと、選択モーダルを表示する
    showRouteSelectModal() {
      // 設定した条件があればそのままにする、ない場合、default値で設定
      this.setRouteSelectModalSearchParam();
      this.isRouteSelectModalShow = true;
      this.routeModalSearchHandler();
    },

    // 設定した条件があればそのままにひょうじする
    setRouteSelectModalSearchParam() {
      const currentSearchParam = this.routeModalSearchParam;
      this.routeModalSearchParam = {
        jwnetItemTypeId: currentSearchParam.jwnetItemTypeId !== "" ? currentSearchParam.jwnetItemTypeId : "",
        routeName: currentSearchParam.routeName !== "" ? currentSearchParam.routeName : "",
        wasteName: currentSearchParam.wasteName !== "" ? currentSearchParam.wasteName : "",
        collectCompanyId: currentSearchParam.collectCompanyId,
        disposalCompanyId: currentSearchParam.disposalCompanyId,
        disposalSiteId: currentSearchParam.disposalSiteId,
        wasteTypeIdList: currentSearchParam.wasteTypeIdList.length > 0 ? currentSearchParam.wasteTypeIdList : [],
        manifestTypeIdList: currentSearchParam.manifestTypeIdList.length > 0 ? currentSearchParam.manifestTypeIdList : [],
        industrialWasteJwnetTypeIdList: currentSearchParam.industrialWasteJwnetTypeIdList.length > 0 ? currentSearchParam.industrialWasteJwnetTypeIdList : [],
        isFavorite: currentSearchParam.isFavorite !== false ? currentSearchParam.isFavorite : false,
      }
    },

    // ルート選択肢モーダルに「検索条件をリセット」ボタンを押すと
    initRouteModalSearchParam() {
      this.selectedCollectCompany = ''
      this.selectedDisposalCompany = ''
      this.selectedDisposalSite = ''
      this.disposalSiteList = this.disposalSiteOptions;
      this.routeModalSearchParam = {
        jwnetItemTypeId: "",
        routeName: "",
        wasteName: "",
        collectCompanyId: null, // 運搬事業者Id
        disposalCompanyId: null, // 処分事業者Id
        disposalSiteId: null, // 処分場ID
        wasteTypeIdList: [],//廃棄物区分
        manifestTypeIdList: [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        isFavorite: false,
      }
    },

    // ルート選択肢モーダルの検索情報をクリアする
    resetRouteModalSearchHandler(){
      this.initRouteModalSearchParam()
    },

    // ルート選択肢モーダルに「検索」ボタンを押す処理
    routeModalSearchHandler() {
      this.getRouteListSearchApi()
    },

    clickSetRoute(routeId) {

      // ルートを変更する場合のみ、フォームの初期化
      if(routeId != this.formValue.manifestInfo.routeId) {
        this.resetRouteInfoFromFormValue(this.formValue)
      }

      if (routeId != null && routeId !== "") {
        this.validate.route.errMsg = "";
      }
      this.formValue.manifestInfo.routeId = routeId;
      this.isRouteSelectModalShow = false;
      this.getConfirmMemberApi(routeId);
      //フォームの更新
      this.onChangeRouteHandler();
    },

    getFirstCollectionDateWeekOfDayLabel() {
      const target = new Date(this.formValue.scheduleSettingInfo.firstCollectionDate);
      return WEEK_OF_DAY[target.getDay()].label;
    },

    initCustomRecurryin() {
      this.formValue.recurringInfo.interval = "";
      this.formValue.recurringInfo.recurringTypeId = "";
      this.formValue.recurringInfo.weekOfDayTypeId = [];
    },
    // 繰り返しのchange処理
    onChangeRecurryingHandler() {
      if (this.selectedRecurrying === "custom") {
        this.isRecurryingModalShow = true;
      }
    },

    // 繰り返しモーダル閉じる（モーダル内設定初期化）
    closeCustomRecurryModalHandler() {
      this.isRecurryingModalShow = false;
    },

    // 繰り替えしモーダル内登録押下
    setCustomRecurryinHandler() {
      switch (this.formValue.recurringInfo.recurringTypeId) {
        case DOMAIN.RECURRING_TYPE.DAY:
          this.weekOfDayTypeIdList = [];
          this.isRecurryingModalShow = false;
          break;
        case DOMAIN.RECURRING_TYPE.WEEK:
          if (this.weekOfDayTypeIdList.length) {
            this.isRecurryingModalShow = false;
          }
      }
    },

    // 排出業者選択
    changeProxyGenerateHandler() {
      //事業場
      this.getGenerateStoreApi(this.proxyGenerateId);
      this.formValue.generateStoreId = null;
      // 登録担当者, 引き渡し担当者の初期化
      this.memberOpt = [];
      this.generateMemberOpt = [];
      this.selectedRoute = null;
      this.formValue.manifestInfo.registMember.id = null;
      this.formValue.manifestInfo.registMember.name = null;
      this.formValue.manifestInfo.generateMember.id = null;
      this.formValue.manifestInfo.generateMember.name = null;
    },

    // 画面内下部の登録押下
    checkRequiredHandler() {
      this.errMsgs = [];

      this.runValidate().then((isValidForm) => {
        if (!isValidForm) {
          this.processing = false;
          this.errMsgs.push("入力に誤りがあります。");
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        this.isConfirmModalShow = true;

      });
    },

    // モーダル内の登録押下
    submit() {
      this.errMsg = [];
      this.isCallAPIProcess = true

      const postData = JSON.parse(JSON.stringify(this.formValue));

      // フォーム整形
      // ルート情報取得の代理登録自動承認可能フラグがONの場合マニフェストステータスを 予約登録中(10)、代理登録自動承認可能フラグがOFFの場合、 マニフェストステータスを 予約承認待ち(12)
      postData.manifestInfo.manifestStatusId = this.routeDetailInfo.isAutoApproval ? DOMAIN.MANIFEST_STATUS.UNDER_RESERVE : DOMAIN.MANIFEST_STATUS.RESERVE_PENDING;
      postData.scheduleSettingInfo.registMemberId = postData.manifestInfo.registMember.id;

      if (postData.anyGenerateStoreInfo.anyGenerateStoreName.length === 0 &&
        postData.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length === 0 &&
        postData.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId.length === 0 &&
        postData.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length === 0 &&
        postData.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length === 0
      ) {
        delete postData.anyGenerateStoreInfo;
      } else {
        if (postData.anyGenerateStoreInfo.anyGenerateStoreName.length === 0) {
          delete postData.anyGenerateStoreInfo.anyGenerateStoreName;
        }

        if (postData.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length === 0 &&
          postData.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId.length === 0 &&
          postData.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length === 0 &&
          postData.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length === 0) {
          delete postData.anyGenerateStoreInfo.anyGenerateStoreAddress;
        }
      }
      // 任意事業場名を全角に置換する
      if (postData.anyGenerateStoreInfo && postData.anyGenerateStoreInfo.anyGenerateStoreName.length) {
        postData.anyGenerateStoreInfo.anyGenerateStoreName = this.toFullWidthString(postData.anyGenerateStoreInfo.anyGenerateStoreName)
      }
      if (postData.anyGenerateStoreInfo && postData.anyGenerateStoreInfo.anyGenerateStoreAddress && postData.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
        postData.anyGenerateStoreInfo.anyGenerateStoreAddress.address = this.toFullWidthStringForAddressInfo(postData.anyGenerateStoreInfo.anyGenerateStoreAddress.address)
      }
      if (postData.anyGenerateStoreInfo && postData.anyGenerateStoreInfo.anyGenerateStoreAddress && postData.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length) {
        postData.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName = this.toFullWidthStringForAddressInfo(postData.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName)
      }
      // 連絡番号
      postData.contactNoInfo.contactNoList = this.formValue.contactNoInfo.contactNoList.map(contactNo => {
            return { contactNo: contactNo.contactNo }
          });

      // 備考
      postData.remarksInfo.remarksList = this.formValue.remarksInfo.remarksList.map(remarks => {
          return { remarks: remarks.remarks }
        });
      postData.labelInfo.labels = this.formValue.labelInfo.labels.map(label => {
          return { labelId: label.id, labelValue: label.labelValue };
        });

      // 詳細名称を全角に変換
      postData.wasteInfo.detailWasteName = this.formValue.wasteInfo.detailWasteName ? this.toFullWidthString(this.formValue.wasteInfo.detailWasteName) : null
      switch (this.selectedRecurrying) {
        case "": // 繰り返さない
          postData.scheduleSettingInfo.isRecurring = false;
          delete postData.recurringInfo;
          break;
        case "every-day": // 毎日
          postData.scheduleSettingInfo.isRecurring = true;
          postData.recurringInfo.recurringTypeId = DOMAIN.RECURRING_TYPE.DAY;
          postData.recurringInfo.intervalValue = 1;
          delete postData.recurringInfo.weekOfDayTypeList;
          break;
        case "every-week": // 毎週x曜日
          postData.scheduleSettingInfo.isRecurring = true;
          postData.recurringInfo.recurringTypeId = DOMAIN.RECURRING_TYPE.WEEK;
          postData.recurringInfo.intervalValue = 1;
          postData.recurringInfo.weekOfDayTypeList = [{ weekOfDayTypeId: (new Date(this.formValue.scheduleSettingInfo.firstCollectionDate)).getDay() + 1 }];
          break;
        case "custom": // カスタム
          postData.scheduleSettingInfo.isRecurring = true;
          postData.recurringInfo.recurringTypeId = this.formValue.recurringInfo.recurringTypeId;
          postData.recurringInfo.intervalValue = this.formValue.recurringInfo.intervalValue;
          postData.recurringInfo.weekOfDayTypeList = this.weekOfDayTypeIdList.map(id => { return { weekOfDayTypeId: id } });
          break;
      }

      if (postData.scheduleSettingInfo.collectionTime === '') {
        postData.scheduleSettingInfo.collectionTime = null;
      }

      if (!this.formValue.scheduleSettingInfo.firstCollectionDate) {
        delete postData.scheduleSettingInfo;
      }

      if (this.selectedPrefectureTemp) {
        this.prefectures.map((prefecture) => {
          if (prefecture.name === this.selectedPrefectureTemp) {
            postData.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId = prefecture.id;
          }
        })
      }

      // harmfulInfoのharmfulTypeIdがnullなら削除
      postData.harmfulInfo = postData.harmfulInfo.filter(hi => hi.harmfulTypeId);

      // 番号取得方法対応は、運搬事業者の代理予約が対象外
      postData.isUseAcquiredManifestNo = false;

      //登録担当者名の再設定
      if (!postData.manifestInfo.registMember.name && !postData.manifestInfo.registMember.id) {
        delete postData.manifestInfo.registMember
      } else {
        if (this.isInputRegisterMemberForm) {
          // 選択肢の場合、登録担当者名称をIDで再設定
          postData.manifestInfo.registMember.name = this.memberOpt.filter(m => m.id == postData.manifestInfo.registMember.id)[0].name;
        } else {
          // 直接入力の場合、nullに変換を行う
          postData.manifestInfo.registMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(postData.manifestInfo.registMember.name));
          // 名称がない場合、登録担当者を削除
          if (!postData.manifestInfo.registMember.name) {
            delete postData.manifestInfo.registMember
          }
        }
      }

      //引き渡し担当者名の再設定
      if (!postData.manifestInfo.generateMember.name && !postData.manifestInfo.generateMember.id) {
        delete postData.manifestInfo.generateMember
      } else {
        if (this.isInputGenerateMemberForm) {
          // 選択肢の場合、引き渡し担当者名称をIDで再設定
          postData.manifestInfo.generateMember.name = this.memberOpt.filter(m => m.id == postData.manifestInfo.generateMember.id)[0].name;
        } else {
          // 直接入力の場合、nullに変換を行う
          postData.manifestInfo.generateMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(postData.manifestInfo.generateMember.name));
          // 名称がない場合、引き渡し担当者を削除
          if (!postData.manifestInfo.generateMember.name) {
            delete postData.manifestInfo.generateMember
          }
        }
      }

      // 数量確定者は設定できないものであれば、リセットする
      if (postData.wasteInfo.quantityConfirmBusinessTypeId
          && !this.confirmMemberOpt.some(confirmMemberOpt => confirmMemberOpt.id === postData.wasteInfo.quantityConfirmBusinessTypeId)){
        postData.wasteInfo.quantityConfirmBusinessTypeId = null;
      }

      axios
        .post(`${API_URL.MANIFEST.CREATE}`, postData)
        .then(() => {
          // マニフェスト一覧へ
          this.$cookies.set(COOKIE_INFO.MESSAGE, "マニフェストを予約しました。");
          this.$router.push(`/manifests/all`);
        })
        .catch((err) => {
          this.isCallAPIProcess = false
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    generateStoreChangeHandler() {
      if (this.formValue.generateStoreId != null) {
        this.getMemberOptApi(this.formValue.generateStoreId);
        // 排出側の動作を合わせて引き渡し担当者を登録担当者と同じにする
        // this.getGenerateMemberApi(this.formValue.generateStoreId);
        this.getRouteListSearchApi();
        this.getEntrustJwnetItemsApi(this.formValue.generateStoreId);
        // 登録担当者, 引き渡し担当者の初期化
        this.formValue.manifestInfo.registMember.id = null;
        this.formValue.manifestInfo.registMember.name = null;
        this.formValue.manifestInfo.generateMember.id = null;
        this.formValue.manifestInfo.generateMember.name = null;
        this.selectedRoute = null;
      }
    },

    // 登録担当者のラジオボタンを変更すると、登録担当者をリセット
    changeRegistMemberInputForm() {
      this.formValue.manifestInfo.registMember.id = null;
      this.formValue.manifestInfo.registMember.name = null;
      this.validate.registMemberName = { isValid: true, errMsg: "" };
    },

    // 引き渡し担当者のラジオボタンを変更すると、引き渡し担当者をリセット
    changeGenerateMemberInputForm() {
      this.formValue.manifestInfo.generateMember.id = null;
      this.formValue.manifestInfo.generateMember.name = null;
      this.validate.generateMemberName = { isValid: true, errMsg: "" };
    },

    downloadWDS() {
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + this.routeDetailInfo.routeId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, this.routeDetailInfo.wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 戻るボタン押下
    backHandler() {
      this.$router.go(-1);
    },
    updateGenerateStoreList(text) {
      if (!text || text.length === 0) {
        //全ての事業場の選択肢を表示する
        this.selectGenerateStoreList = this.generateStoreList
        return
      }
      this.selectGenerateStoreList = this.generateStoreList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateCollectCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectCompanyCompanyList = this.collectCompanyList;
        return
      }
      this.selectCompanyCompanyList = this.collectCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectDisposalCompanyList = this.disposalCompanyList;
        return
      }
      this.selectDisposalCompanyList = this.disposalCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        //全ての処分場の選択肢を表示する
        this.selectDisposalSiteList = this.disposalSiteList;
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel(item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
    onChange(queryString, ref) {
      this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
      });
    },
  },
  computed: {

    // 有害物質選択肢表示かチェック
    isDisplayHarmfulSelection: function() {
      const selectedJwnetItemId = this.formValue.wasteInfo.jwnetItemTypeId;
      const filteredJwnetItems = this.jwnetItemsOpt.filter(jwnetItem => jwnetItem.id === selectedJwnetItemId);

      if (filteredJwnetItems.length > 0) {
        return filteredJwnetItems[0].isHarmful;
      }

      return false;

    },

    // 件数出力1~10、以降100まで10毎
    getReserveNumber() {
      const count = 100;
      let arry = [];
      for (let i = 1; i <= count; i++) {
        if (i < 10 || i % 10 === 0) {
          arry.push(i);
        }
      }
      return arry;
    },
    // 間隔出力1~10、以降30まで10毎
    getIntervals() {
      const count = CUSTOM_MAX_INTERVAL;
      let arry = [];
      for (let i = 1; i <= count; i++) {
        if (i < 10 || i % 10 === 0) {
          arry.push(i);
        }
      }
      return arry;
    },
  },

  watch: {
    selectedAddress() {
      this.selectedPrefectureTemp = this.selectedAddress.address1;
      this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address = this.selectedAddress.address2;
      this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName = this.selectedAddress.address3
    },
    selectedGenerateStore: function(newVal) {
      this.formValue.generateStoreId = newVal ? newVal.id : null
      this.generateStoreChangeHandler()
      this.resetValidateRouteInfo(this.validate)
      this.resetRouteAndCompanyInfo()
      this.resetRouteInfoFromFormValue(this.formValue)
    },
    selectedCollectCompany: function (newVal) {
      this.routeModalSearchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalCompany: function (newVal) {
      this.selectedDisposalSite = '';
      this.routeModalSearchParam.disposalCompanyId = newVal ? newVal.id : ''
      if (this.routeModalSearchParam.disposalCompanyId) {
        this.disposalSiteList = this.disposalSiteOptions.filter(
            (ds) => ds.disposalCompanyId == newVal.id
        )
        this.selectDisposalSiteList = this.disposalSiteList;
      } else {
        this.disposalSiteList = this.disposalSiteOptions
        this.selectDisposalSiteList = this.disposalSiteList
      }
    },
    selectedDisposalSite: function (newVal) {
      this.routeModalSearchParam.disposalSiteId = newVal ? newVal.id : ''
    },
  },

  mounted() {
    if (this.$route.query.date) {
      this.formValue.scheduleSettingInfo.firstCollectionDate = moment(this.$route.query.date).format("YYYY-MM-DD");
    }

    // 初期表示に必要なAPIを呼ぶ
    this.getPrefecturesApi();
    this.getRecurryingApi();
    this.getWasteUnitApi();
    this.getProxyGenerateCompanyApi();
    this.getHarmfulApi();
    this.getCollectMemberApi();
    this.getDisposalSiteApi();
    this.getCollectCompanyForGenerate();
    this.getDisposalCompany();

    // 代理登録担当者の初期値セット
    this.formValue.manifestInfo.proxyCollectMemberId = this.$store.getters["userInfo/get"].id;
  },
};
</script>

<style scoped>
.md-m {
  width: 28rem;
}
</style>